import React, { ReactElement } from 'react';
import { Set } from 'immutable';
import { Cart } from 'mapado-ticketing-js-sdk';
import { SeatConfigType } from '../propTypes';
import SelectSeatCaption from './Caption/SelectSeatCaption';
import CartTile from './CartReplacement/CartTile';
import { getAvailableSeatListFromCart } from '../utils/memoized';
import '../styles/components/ReplacementSidebar.scss';

export default function CartSidebar({
  seatConfig,
  selectedSeatIdSet,
  cart,
}: Props): ReactElement {
  return (
    <div className="mpd-seating__order-sidebar">
      <div className="replacement-sidebar__content">
        <div className="replacement-sidebar__name">{seatConfig.label}</div>
        <hr />
        <div>
          <SelectSeatCaption
            cartOrOrder={cart}
            selectedSeatIdSet={selectedSeatIdSet}
            nbSeatsInBooking={getAvailableSeatListFromCart(cart).size}
          />
        </div>

        <table className="mpd-table mpd-table--narrow mt0">
          <tbody>{cart && <CartTile cart={cart} />}</tbody>
        </table>
      </div>
    </div>
  );
}

type CartSidebarProps = {
  selectedSeatIdSet: Set<number> | null;
  seatConfig: SeatConfigType;
};

type StateProps = {
  cart: Cart;
};

type Props = CartSidebarProps & StateProps;
