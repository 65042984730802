import React, { PureComponent, ReactElement } from 'react';
import { Set } from 'immutable';
import { Cart } from 'mapado-ticketing-js-sdk';
import { MpdToastFunction, useMpdToast } from '@mapado/makeup';
import OrderViewerSeat from '../Seat/OrderViewerSeat';
import SidebarTemplate from '../Sidebar/SidebarTemplate';
import Seating from '../Seating';
import EventDateCaptionWithFetch from '../Caption/EventDateCaption';
import SeatingLoader from '../SeatingLoader';
import { AvailableSeatType, SeatConfigType } from '../../propTypes';
import { getSelectableSeatIdSetForOrderViewer } from '../../utils/seatSelectable';
import { getSeatIdForAvailableSeat } from '../../utils/entity';
import useCart, { ReplaceAvailableSeatFunction } from '../useCart';
import { CartContextProvider } from '../CartReplacement/CartContext';
import { getAvailableSeatListFromCart } from '../../utils/memoized';
import '../../styles/components/App.scss';
import '../../styles/contexts/viewer.scss';
import { SelectOrMoveSeatAction } from '../../actions/SeatActions';
import { isCartEntity } from '../../utils/booking';
import { TFunction, useTranslation } from '../../i18n';

function OrderViewerSidebar({
  nbSelectedSeat,
  nbSeatsInBooking,
}: OrderViewerSidebarProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="mpd-seating__viewer-sidebar">
      <div className="mpd-seating__viewer-sidebar__top">
        <div className="mpd-seating__viewer-sidebar__instruction">
          <div className="mb3">
            <div className="larger">
              {t('seating.move_seat.selected_seats', {
                count: nbSelectedSeat,
              })}
            </div>
            <div className="smaller">
              {t('seating.move_seats.nb_seats_in_order', {
                count: nbSeatsInBooking,
              })}
            </div>
          </div>

          {nbSelectedSeat > 0 && (
            <p className="small mt0">
              {t('seating.help_move_seats', { count: nbSelectedSeat })}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

type OrderViewerSidebarProps = {
  nbSelectedSeat: number;
  nbSeatsInBooking: number;
};

/**
 * @deprecated The order viewer can be replaced by the `CartReplacement` component.
 * In this case, we need to check if we need to hide the `CartTile` on the desk.
 */
class OrderViewer extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.init = this.init.bind(this);
    this.selectOrMoveSeat = this.selectOrMoveSeat.bind(this);
  }

  componentDidMount(): void {
    const { cartId } = this.props;

    if (!cartId) {
      throw new Error('CartId is required');
    }

    this.init();
  }

  componentWillUnmount(): void {
    const { resetState } = this.props;

    resetState();
  }

  init(): void {
    const { eventDateId, cartId, initOrderViewer } = this.props;

    initOrderViewer(cartId, eventDateId);
  }

  selectOrMoveSeat(
    seatElement: AvailableSeatType,
    isSelected: boolean,
    isSelectable: boolean
  ): void {
    const { cart, selectOrMoveSeat, replaceAvailableSeat, toast, t } =
      this.props;

    if (!cart) {
      return;
    }

    const seatId = getSeatIdForAvailableSeat(seatElement);
    const isCurrentBooking = isCartEntity(seatElement);

    selectOrMoveSeat(
      cart,
      seatId,
      isSelectable,
      isCurrentBooking,
      cart,
      replaceAvailableSeat,
      this.init,
      toast,
      t
    );
  }

  render(): null | ReactElement {
    const {
      eventDateId,
      isReady,
      getSelectableSeatIdSet,
      seatConfig,
      selectedSeatIdSet,
      cart,
    } = this.props;

    if (!isReady) {
      return <SeatingLoader />;
    }

    if (!seatConfig) {
      return null;
    }

    return (
      <div className="mpd-seating__app context-viewer" id="mpd-seating__app">
        <div className="mpd-seating__app__container">
          <div className="mpd-seating__app__event-svg-wrapper">
            <EventDateCaptionWithFetch eventDateId={eventDateId} />
            <Seating
              seatEntityType="AvailableSeat"
              SeatElement={OrderViewerSeat}
              getSelectableSeatIdSet={(state, seatIdSet) => {
                if (cart) {
                  return getSelectableSeatIdSet(state, seatIdSet, cart);
                }

                return Set();
              }}
              onClickSeat={this.selectOrMoveSeat}
              isMovingSeat={!!(selectedSeatIdSet && selectedSeatIdSet.size > 0)}
              seatConfigBlockList={
                seatConfig ? seatConfig.seatConfigBlockList : []
              }
            />
          </div>
          <SidebarTemplate>
            {cart && (
              <OrderViewerSidebar
                nbSelectedSeat={
                  (selectedSeatIdSet && selectedSeatIdSet.size) ?? 0
                }
                nbSeatsInBooking={getAvailableSeatListFromCart(cart).size}
              />
            )}
          </SidebarTemplate>
        </div>
      </div>
    );
  }
}

export type OrderViewerProps = {
  eventDateId: number;
  cartId: number;
};

type PropsFromContainer = {
  toast: MpdToastFunction;
  t: TFunction;
  cart: Cart | null;
  replaceAvailableSeat: ReplaceAvailableSeatFunction;
};

export type StateProps = {
  seatConfig: null | SeatConfigType;
  isReady: boolean;
  getSelectableSeatIdSet: typeof getSelectableSeatIdSetForOrderViewer;
  selectedSeatIdSet: Set<number> | null;
};

export type DispatchProps = {
  resetState: () => void;
  initOrderViewer: (cartId: number, eventDateId: number) => void; // TODO cartId ??
  selectOrMoveSeat: SelectOrMoveSeatAction;
};

type Props = StateProps & DispatchProps & OrderViewerProps & PropsFromContainer;

export default function OrderViewerContainer(
  props: Omit<Props, keyof PropsFromContainer>
): ReactElement {
  const { cartId, eventDateId } = props;
  const toast = useMpdToast();
  const { t } = useTranslation();
  const [cart, replaceAvailableSeat] = useCart(cartId, eventDateId);

  return (
    <CartContextProvider cart={cart}>
      <OrderViewer
        {...props}
        cart={cart}
        replaceAvailableSeat={replaceAvailableSeat}
        toast={toast}
        t={t}
      />
    </CartContextProvider>
  );
}
