import React, { FocusEvent, useRef, useState } from 'react';
import Select, {
  InputAction,
  SelectInstance,
  SingleValue as SingleValueType,
} from 'react-select';
import Creatable from 'react-select/creatable';
import cn from 'classnames';
import { generateRandomID } from '../../../js-libs/utils';
import DropdownIndicator from './common/DropdownIndicator';
import ClearIndicator from './common/ClearIndicator';
import LoadingIndicator from './common/LoadingIndicator';
import GroupHeading from './common/GroupHeading';
import Option from './single/Option';
import Control from './single/Control';
import ValueContainer from './single/ValueContainer';
import SingleValue from './single/SingleValue';
import { useTranslation } from '../../../i18n';
import {
  getSelectTheme,
  isGroupedOption,
  SelectGroupedOption,
  SingleSelectOption,
} from './common/utils';
import useLoadData, { LoadOptionsType } from './common/useLoadData';
import debounce from '../../../utils/debounce';
import InputMessagesWrapper from '../InputMessagesWrapper';
import InputLabel from '../InputLabel';

export type MpdSingleSelectProps = {
  id?: string;
  autoFocus?: boolean;
  placeholder?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  value?: SingleSelectOption | null;
  classnames?: string;
  errorMessage?: string | boolean | null | undefined;
  helpMessage?: string | boolean | null | undefined;
  autocomplete?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange?: (option: SingleSelectOption | null) => void;
  filterOption?: (option: SingleSelectOption, rawInput: string) => boolean;
} & (
  | {
      creatable?: true;
      searchable?: true;
      clearable?: boolean;
    }
  | {
      creatable?: false;
      searchable?: boolean;
      clearable?: boolean;
    }
) &
  (
    | {
        searchable: true;
        defaultValue?: never;
        clearable?: boolean;
      }
    | {
        searchable?: false;
        defaultValue?: SingleSelectOption;
        clearable?: boolean;
      }
  ) &
  (
    | {
        loadOptions: LoadOptionsType<
          SingleSelectOption | SelectGroupedOption<SingleSelectOption>
        >;
        options?: never;
        searchable?: true;
        clearable?: boolean;
      }
    | {
        loadOptions?: never;
        options:
          | Array<SingleSelectOption>
          | Array<SelectGroupedOption<SingleSelectOption>>
          | [];
        searchable?: boolean;
        clearable?: boolean;
      }
  );

function MpdSingleSelect({
  id,
  placeholder,
  label,
  options,
  loadOptions,
  required = false,
  disabled = false,
  searchable = false,
  creatable = false,
  autocomplete = false,
  name,
  value,
  defaultValue,
  classnames,
  errorMessage,
  helpMessage,
  onFocus,
  onBlur,
  onChange,
  filterOption,
  autoFocus = false,
  clearable,
}: MpdSingleSelectProps): JSX.Element {
  const { t } = useTranslation();
  const selectRef = useRef<SelectInstance<SingleSelectOption, false> | null>(
    null
  );
  const [isLoading, hasMore, loadedOptions, loadData, searchData] = useLoadData<
    SingleSelectOption | SelectGroupedOption<SingleSelectOption>
  >(loadOptions);

  const [currentPage, setCurrentPage] = useState(1);

  const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleOnChange = (option: SingleValueType<SingleSelectOption>) => {
    if (onChange) {
      onChange(option);
    }
  };

  const handleInputChange = (
    newValue: string,
    { action }: { action: InputAction }
  ) => {
    const query = newValue || null;

    if (action === 'input-change') {
      searchData(query);
      setCurrentPage(1);
    }

    if (action === 'menu-close') {
      searchData(null);
      setCurrentPage(1);
    }
  };

  const handleLoadMore = () => {
    if (!hasMore) {
      return;
    }

    loadData(currentPage + 1);
    setCurrentPage((prev) => prev + 1);
  };

  const getOptionsProps = () => {
    if (typeof loadOptions === 'function') {
      return {
        isLoading,
        loadingMessage: () => t('loading'),
        options: loadedOptions,
        onInputChange: debounce(handleInputChange, 600),
        onMenuScrollToBottom: debounce(handleLoadMore),
      };
    }

    if (creatable) {
      return {
        options, // options have been set as prop. No need to load options
        onInputChange: handleInputChange,
      };
    }

    return {
      options, // options have been set as prop. No need to load options
      defaultValue,
    };
  };

  const optionsProps = getOptionsProps();
  const components = {
    DropdownIndicator: autocomplete ? null : DropdownIndicator,
    ClearIndicator,
    LoadingIndicator,
    Control,
    Option,
    ValueContainer,
    GroupHeading,
    SingleValue,
  };
  const selectId = id ?? generateRandomID();
  const SelectComponent = creatable ? Creatable : Select;

  const isSearchable = creatable || searchable || !!loadOptions || autocomplete; // Creatable, Async and Autcomplete select are always searchable.

  const isGrouped =
    optionsProps.options?.some((option) => isGroupedOption(option)) ?? false;

  return (
    <div className={cn('mpd-single-select', classnames)}>
      <InputLabel id={selectId} label={label} required={required} />
      <SelectComponent
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...optionsProps}
        menuPlacement="auto"
        minMenuHeight={300}
        ref={selectRef}
        id={selectId}
        name={name}
        placeholder={placeholder}
        required={required}
        value={value}
        isDisabled={disabled}
        isSearchable={isSearchable}
        isClearable={clearable ?? isSearchable}
        isOptionDisabled={(option: SingleSelectOption) =>
          option.disabled ?? false
        }
        formatCreateLabel={(optionName) =>
          t('multi_select.add_option', { optionName })
        }
        noOptionsMessage={() => t('multi_select.no_option')}
        isGrouped={isGrouped}
        autocomplete={autocomplete}
        openMenuOnClick={!autocomplete}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onChange={handleOnChange}
        filterOption={filterOption}
        autoFocus={autoFocus}
        components={components}
        theme={(theme) => getSelectTheme(theme, errorMessage)}
        classNames={{
          indicatorSeparator: () => 'hidden',
          placeholder: () => (placeholder ? 'mpd-color-gray' : 'hidden'),
          control: ({ isFocused }: { isFocused: boolean }) =>
            cn('mpd-single-select-control', {
              'mpd-single-select-control--error': errorMessage,
              'mpd-single-select-control--is-focused': isFocused,
            }),
          input: (props) =>
            cn('mpd-single-select-input', {
              hidden: autocomplete && !!props.selectProps.value,
            }),
          valueContainer: () => 'mpd-single-select-value-container',
          option: () => 'mpd-single-select-option',
          singleValue: () => 'mpd-single-select-single-value',
          menu: (props) =>
            cn('mpd-select-menu', {
              hidden: autocomplete && !props.selectProps.inputValue,
            }),
        }}
      />
      <InputMessagesWrapper help={helpMessage} errorMessage={errorMessage} />
    </div>
  );
}

export default MpdSingleSelect;
